import apiClient from '@/apiClient';
import store from '@/store';
import { changeLocale } from '@/plugins/i18n';

export default {
  /**
   * Login user.
   *
   * @param {Object} payload
   *
   * @return {Promise<Object>}
   */
  async login(payload) {
    await apiClient.get('/auth/csrf-cookie');
    return apiClient.post('/auth/login', payload);
  },
  /**
   * Confirm two factor code.
   *
   * @param {String} code
   *
   * @return {Promise<Object>}
   */
  async confirmTwoFactor(code) {
    return apiClient.post('/auth/two-factor-challenge', {
      code: `${code}`,
    });
  },
  /**
   * Logout.
   *
   * @return {Promise}
   */
  logout() {
    if (store.auth.user.impersonating) {
      return apiClient.get('/auth/impersonate/leave');
    }

    return apiClient.post('/auth/logout');
  },
  /**
   * Fetch user.
   *
   * @return {Promise<Object>}
   */
  async fetchUser() {
    const result = await apiClient.get('/auth/me');
    store.auth.user = result.data;
    changeLocale(result.data.language);
    return store.auth.user;
  },
  /**
   * Logged in check.
   *
   * @return {Promise<Boolean>}
   */
  async loggedIn() {
    if (store.auth.user && store.auth.user.id) {
      return true;
    }

    try {
      await this.fetchUser();
      return true;
    } catch {
      return false;
    }
  },
  /**
   * Determine redirect route.
   *
   * @param {Object} user
   */
  redirectRoute(user) {
    if (user.customer_id) {
      return { name: 'my', params: { portal: user.partner_id } };
    }

    return {
      name: 'admin',
      params: {
        portal: user.partner_id,
      },
    };
  },
  /**
   * Check whether user has given permission.
   *
   * @param   {String}  permission
   *
   * @return  {Boolean}
   */
  hasPermission(permission) {
    return store.auth.user.all_permissions.includes(permission) || store.auth.user.role === 'Super admin';
  },
  /**
   * Check whether user has given provoice permission.
   *
   * @param   {String}  id
   * @param   {String}  type
   * @param   {String}  permission
   *
   * @return  {Boolean}
   */
  hasProvoicePermission(id, type, permission) {
    return store.auth.user.role === 'Super admin' || ((store.auth.user.provoice_permissions[type][id] && store.auth.user.provoice_permissions[type][id].includes(permission)) ?? false);
  },
};
