import store from '@/store';

export default [
  {
    name: 'index',
    path: '/',
    component: () => import('@/views/Index.vue'),
  },
  {
    name: 'login',
    path: '/login',
    component: () => import('@/views/Login.vue'),
  },
  {
    name: 'two-factor',
    path: '/2fa',
    component: () => import('@/views/TwoFactor.vue'),
  },
  {
    name: 'password',
    path: '/wachtwoord',
    component: () => import('@/views/Password.vue'),
  },
  {
    name: 'password-reset',
    path: '/wachtwoord-reset/:token/:email',
    component: () => import('@/views/PasswordReset.vue'),
    props: (route) => ({
      token: route.params.token,
      email: route.params.email,
    }),
  },
  {
    path: '/admin',
    redirect() {
      if (store.auth.user.partner_id) {
        return {
          name: 'admin',
          params: {
            portal: store.auth.user.partner_id,
          },
        };
      }

      return { name: 'login' };
    },
  },
  {
    name: 'admin',
    path: '/admin/:portal',
    component: () => import('@/views/Admin.vue'),
    redirect() {
      if (store.auth.user.customer_id) {
        return { name: 'my', params: { portal: store.auth.user.partner_id } };
      }

      return { name: 'customers-active' };
    },
    props: (route) => ({
      portal: route.params.portal,
    }),
    children: [
      {
        name: 'account',
        path: 'account',
        component: () => import('@/views/MyAccount.vue'),
      },
      {
        path: 'klanten',
        children: [
          {
            name: 'customers',
            path: '',
            component: () => import('@/views/Customers.vue'),
            redirect: { name: 'customers-active' },
            children: [
              {
                name: 'customers-active',
                path: 'actief',
                component: () => import('@/views/Customers.vue'),
                props: (route) => ({
                  portal: route.params.portal,
                }),
              },
              {
                name: 'customers-inactive',
                path: 'archief',
                component: () => import('@/views/Customers.vue'),
                props: (route) => ({
                  portal: route.params.portal,
                }),
              },
            ],
          },
          {
            path: ':customer',
            component: () => import('@/views/CustomerDetail.vue'),
            props: (route) => ({
              id: route.params.customer,
            }),
            children: [
              {
                name: 'customer',
                path: 'overzicht',
                component: () => import('@/views/CustomerInfo.vue'),
              },
              {
                name: 'customer-trunks',
                path: 'trunks',
                component: () => import('@/views/Trunks.vue'),
                redirect: { name: 'customer-trunks-active' },
                children: [
                  {
                    name: 'customer-trunks-active',
                    path: 'actief',
                    component: () => import('@/views/Trunks.vue'),
                  },
                  {
                    name: 'customer-trunks-inactive',
                    path: 'inactief',
                    component: () => import('@/views/Trunks.vue'),
                  },
                ],
              },
              {
                name: 'customer-numbers',
                path: 'nummers',
                component: () => import('@/views/Numbers.vue'),
              },
              {
                name: 'customer-conversations',
                path: 'gesprekken',
                component: () => import('@/views/Conversations.vue'),
                props: (route) => ({
                  customerId: route.params.customer,
                }),
              },
              {
                name: 'customer-log',
                path: 'log',
                component: () => import('@/views/Log.vue'),
              },
            ],
          },
          {
            name: 'customer-add',
            path: 'toevoegen',
            component: () => import('@/views/NewCustomer.vue'),
            props: (route) => ({
              partner: route.params.portal,
            }),
          },
        ],
      },
      {
        path: 'partners',
        children: [
          {
            name: 'partners',
            path: '',
            component: () => import('@/views/Partners.vue'),
          },
          {
            path: ':partner',
            component: () => import('@/views/PartnerDetail.vue'),
            props: (route) => ({
              id: route.params.partner,
            }),
            children: [
              {
                name: 'partner',
                path: 'overzicht',
                component: () => import('@/views/CustomerInfo.vue'),
              },
            ],
          },
          {
            name: 'partner-add',
            path: 'toevoegen',
            component: () => import('@/views/NewPartner.vue'),
            props: (route) => ({
              partner: route.params.portal,
            }),
          },
        ],
      },
      {
        path: 'voorraad',
        children: [
          {
            name: 'stock',
            path: '',
            component: () => import('@/views/Stock.vue'),
          },
        ],
      },
      {
        path: 'logboek',
        children: [
          {
            name: 'logbook',
            path: '',
            component: () => import('@/views/LogBook.vue'),
            props: (route) => ({
              portal: route.params.portal,
            }),
          },
        ],
      },
      // {
      //   path: 'porteringen',
      //   children: [
      //     {
      //       name: 'portings',
      //       path: '',
      //       component: () => import('@/views/Portings.vue'),
      //     },
      //     {
      //       name: 'porting',
      //       path: ':porting',
      //       component: () => import('@/views/PortingDetail.vue'),
      //       props: (route) => ({
      //         id: route.params.porting,
      //       }),
      //     },
      //   ],
      // },
      {
        name: 'users',
        path: 'gebruikers',
        component: () => import('@/views/Users.vue'),
        redirect: { name: 'users-main' },
        children: [
          {
            name: 'users-main',
            path: 'hoofdgebruikers',
            component: () => import('@/views/Users.vue'),
            meta: {
              mode: 'main',
            },
          },
          {
            name: 'users-customers',
            path: 'klanten',
            component: () => import('@/views/Users.vue'),
            meta: {
              mode: 'customer',
            },
          },
        ],
      },
      {
        name: 'order-trunk',
        path: 'bestellen/:customer',
        component: () => import('@/views/TrunkOrder.vue'),
        props: (route) => ({
          id: route.params.customer,
          orderNumbers: route.query.orderNumbers === 'true',
        }),
      },
      {
        name: 'porting-add',
        path: 'porting/:customer',
        component: () => import('@/views/Porting.vue'),
        props: (route) => ({
          id: route.params.customer,
        }),
      },
      {
        name: 'settings',
        path: 'instellingen',
        component: () => import('@/views/Settings.vue'),
        redirect: (route) => ({
          name: route.params.portal === process.env.VUE_APP_PROVOICE_ROOT_CUSTOMER ? 'rate-plans' : 'tariffs',
        }),
        children: [
          {
            name: 'tariffs',
            path: 'tarieven',
            component: () => import('@/views/Tariffs.vue'),
            props: (route) => ({
              portal: route.params.portal,
            }),
          },
          {
            name: 'tariff-plans',
            path: 'tarieflijsten',
            component: () => import('@/views/TariffPlans.vue'),
            props: (route) => ({
              portal: route.params.portal,
            }),
          },
          {
            name: 'tariff-plan-detail',
            path: 'tarieflijsten/:tariffPlan',
            component: () => import('@/views/TariffPlanDetail.vue'),
            props: (route) => ({
              portal: route.params.portal,
              id: route.params.tariffPlan,
            }),
          },
          {
            name: 'rate-plans',
            path: 'basistarieven',
            component: () => import('@/views/Rateplans.vue'),
            props: (route) => ({
              portal: route.params.portal,
            }),
          },
          {
            name: 'rate-plan-detail',
            path: 'basisplan/:ratePlan',
            component: () => import('@/views/RateplanDetail.vue'),
            props: (route) => ({
              portal: route.params.portal,
              id: route.params.ratePlan,
            }),
          },
          {
            name: 'branding',
            path: 'huisstijl',
            component: () => import('@/views/Branding.vue'),
            props: (route) => ({
              id: route.params.portal,
            }),
          },
        ],
      },
    ],
  },
  {
    name: 'my',
    path: '/:portal/mijn',
    component: () => import('@/views/Admin.vue'),
    redirect: { name: 'my-numbers' },
    props: (route) => ({
      portal: route.params.portal,
    }),
    children: [
      {
        name: 'my-account',
        path: 'account',
        component: () => import('@/views/MyAccount.vue'),
      },
      {
        name: 'my-numbers',
        path: 'nummers',
        component: () => import('@/views/AccountNumbers.vue'),
      },
      {
        name: 'my-calls',
        path: 'gesprekken',
        component: () => import('@/views/AccountConversations.vue'),
      },
      {
        name: 'my-users',
        path: 'gebruikers',
        component: () => import('@/views/Users.vue'),
      },
    ],
  },
];
